:root {
  /* 1st color */

  /* --primary-color: #d19f68;
  --primary-color-rgb: rgba(209, 159, 104, 0.7);
  --secondary-color: #002f4b80;
  --secondary-color-rgb: rgba(0, 47, 75, 0.5); */


/* ------------------ provided color ------------------ */

  --primary-color: #d9acf5;
  --primary-color-rgb: rgba(217, 172, 245, 0.7);
  
  --secondary-color: #000000;
  --secondary-color-rgb: rgba(44, 44, 43, 0.685);

  --background-primary-color: #f8f5f2;
  --background-secondary-color: #fff;
  
  --text-primary-color: #000;
  --text-secondary-color: #fff;
  --text-tertiary-color: #d9acf5;

  /* 2nd colors */

  /* --primary-color: #92A4B1;
  --primary-color-rgb: rgba(145, 163, 176, 0.7);
  --secondary-color: #000000;
  --secondary-color-rgb: rgba(0, 0, 0, 0.7);
   */

  /* 3rd colors */

  /* --primary-color: #936F62;
  --primary-color-rgb: rgba(148, 111, 97, 0.7);
  --secondary-color: #000000;
  --secondary-color-rgb: rgba(0, 0, 0, 0.7); */

  /* 4rd colors */

  /* --primary-color: #AD8786;
  --primary-color-rgb: rgba(172, 135, 134, 0.7);
  --secondary-color: #000000;
  --secondary-color-rgb: rgba(0, 0, 0, 0.7); */

  /* 5rd colors */

  /* --primary-color: #AD8786;
  --primary-color-rgb: rgba(172, 135, 134, 0.7);
  --secondary-color: #000000;
  --secondary-color-rgb: rgba(0, 0, 0, 0.7); */



}
